import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  roomSensorDatas: [],
};
export const roomSensorDataSlice = createSlice({
  name: "roomSensorData",
  initialState,
  reducers: {
    roomSensorDataRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
    }),
    roomSensorDataSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      roomSensorDatas: action.payload,
    }),
    roomSensorDataFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    loading: (state, action) => {
      state.isLoading = action.payload
    }
  },
});
export const { roomSensorDataRequest, roomSensorDataSuccess, roomSensorDataFailure, loading } = roomSensorDataSlice.actions;
export default roomSensorDataSlice.reducer;
