import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  treatments: [],
  paging: {},
};
export const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {
    treatmentRequest: (state) => ({
      ...state,
      isLoading: true,
    }),
    treatmentSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      treatments: action.payload,
    }),
    treatmentFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    getPaging: (state, action) => {
      state.paging = action.payload;
    },
  },
});
export const { treatmentRequest, treatmentSuccess, treatmentFailure, getPaging } = treatmentSlice.actions;
export default treatmentSlice.reducer;
