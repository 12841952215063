import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listUser: [],
  userInfo: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  paging: {},
};
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
    }),
    userSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      listUser: action.payload,
    }),
    userFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    getPaging: (state, action) => {
      state.paging = action.payload;
    },
    getUserInfo: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      userInfo: action.payload,
    }),
  },
});
export const { userRequest, userSuccess, userFailure, getPaging, getUserInfo } = userSlice.actions;
export default userSlice.reducer;
