import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  serialNumbers: [],
  paging: {}
};

export const serialNumberSlice = createSlice({
  name: "serialNumber",
  initialState,
  reducers: {
    serialNumberRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    }),
    serialNumberSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      serialNumbers: action.payload,
    }),
    serialNumberFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    setPaging: (state, action) =>{
      state.paging = action.payload
    }
    
  },
});
export const { serialNumberRequest, serialNumberSuccess, serialNumberFailure,setPaging } = serialNumberSlice.actions;
export default serialNumberSlice.reducer;
