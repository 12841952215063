import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  roomSetting: "",
  listRooms: []
};
export const roomSettingSlice = createSlice({
  name: "roomSetting",
  initialState,
  reducers: {
    roomSettingRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }),
    roomSettingSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      roomSetting: action.payload,
    }),
    roomSettingFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    setListRoom: (state, action) => {
      state.listRooms = action.payload
    }
  },
});

export const { roomSettingRequest, roomSettingSuccess, roomSettingFailure, setListRoom } = roomSettingSlice.actions;

export default roomSettingSlice.reducer;
