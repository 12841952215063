import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  displaySensors: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const displaySensorSlice = createSlice({
  name: "displaySensor",
  initialState,
  reducers: {
    displayRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }),
    displaySuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      isError: false,
      displaySensors: action.payload,
    }),
    displayFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
  },
});

export const { displayRequest, displaySuccess, displayFailure } = displaySensorSlice.actions;
export default displaySensorSlice.reducer;
