import authApi from "../../../api/user/authApi";
import { setToken, logout, setUser, loginRequest, loginSuccess, loginFailure } from "./authSlice";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

const keyEncode = process.env.REACT_APP_KEYENCODE;
const inFifteenMinutes = new Date(new Date().getTime() + 1440 * 60 * 1000);

export const login = (userData) => async (dispatch) => {
  try {
    dispatch(loginRequest());

    const response = await authApi.login(userData);
    const { data } = response;
    const { result, code, message } = data;

    if (code === 200) {
      const encodedResult = CryptoJS.AES.encrypt(JSON.stringify(result), keyEncode).toString();
      const { redirect_url, token, user } = result;

      if (redirect_url) {
        window.location.href = redirect_url;
      } else {
        dispatch(loginSuccess());
        Cookies.set("auth", encodedResult, { expires: inFifteenMinutes });
        dispatch(setToken(token));
        dispatch(setUser(user));
      }
    } else {
      dispatch(loginFailure(message));
      toast.error(message);
    }
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

// Action to log out
export const logoutUser = () => (dispatch) => {
  dispatch(logout());
  Cookies.remove("auth");
  Cookies.remove("role");
};
export const reloadUser = (data) => (dispatch) => {
  dispatch(setUser(data.user));
  dispatch(setToken(data.token));
};
