import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  rooms: [],
  room: [],
  paging: {}
};
export const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    roomRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
    }),
    roomSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      rooms: action.payload,
    }),
    roomFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    setPaging: (state, action) =>{
      state.paging = action.payload
    }
  },
});
export const { roomRequest, roomSuccess, roomFailure, setRoom,setPaging } = roomSlice.actions;
export default roomSlice.reducer;
