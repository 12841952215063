import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  isSuccess: false,
  isLoading: false,
  isError: false,
  errorMessage: "",
};
export const userLocationSlice = createSlice({
  name: "userLocation",
  initialState: initialState,
  reducers: {
    userLocationRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    }),
    userLocationSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      users: action.payload,
    }),
    userLocationFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
  },
});
export const { userLocationRequest, userLocationSuccess, userLocationFailure } = userLocationSlice.actions;
export default userLocationSlice.reducer;
