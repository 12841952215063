import {createSlice} from "@reduxjs/toolkit";

export const statusStartSlice = createSlice({
  name: "statusStart",
  initialState: {status: false},
  reducers: {
    setStatusStart: (state, action) => {
      state.status = action.payload
    }
  }
})

export const {setStatusStart} = statusStartSlice.actions;
export default statusStartSlice.reducer