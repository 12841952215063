import axiosClient from "../axiosClient";

const authApi = {
  login: (userData) => {
    const url = "/api/v1/user/login";
    return axiosClient.post(url, userData);
  },
  emailVerify: (token) => {
    const url = '/api/v1/user/email-verify'
    return axiosClient.post(url,token)
  }
};
export default authApi;
