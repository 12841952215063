import axiosClient from "../axiosClient";

const roomSensorDataApi = {
  getRoomSensorData: (id) => {
    const url = `/api/v1/room/room_sensor_data/${id}`;
    return axiosClient.get(url);
  },

  getAllRoomSensorData: (roomId, timeFrom, timeTo, variable, locationId) => {
    const url = `/api/v1/room/room_sensor_data`;

    const params = {
      room_id: roomId,
      from_date: timeFrom,
      to_date: timeTo,
      template_config_variable: variable,
    };

    const headers = {
      "location-id": locationId,
      "room-id": roomId,
    };

    return axiosClient.get(url, { headers, params });
  },

  addRoomSensorData: (data, locationId, roomId) => {
    const url = `/api/v1/room/room_sensor_data`;
    const headers = {
      "location-id": locationId,
      "room-id": roomId,
    };

    return axiosClient.post(url, data, { headers });
  },

  updateRoomSensorData: (id, data, locationId, roomId, oldData) => {
    const url = `/api/v1/room/room_sensor_data/${id}`;
    const headers = {
      "location-id": locationId,
      "room-id": roomId,
      "x-old-value": JSON.stringify(oldData),
    };

    return axiosClient.put(url, data, { headers });
  },

  deleteRoomSensorData: (id, locationId, roomId) => {
    const url = `/api/v1/room/room_sensor_data/${id}`;
    const headers = {
      "location-id": locationId,
      "room-id": roomId,
    };

    return axiosClient.delete(url, { headers });
  },

  exportRoomSensorData: (data, locationId, roomId) => {
    const url = `/api/v1/room/export_room_sensor_data`;
    const headers = {
      "location-id": locationId,
      "room-id": roomId,
    };

    return axiosClient.post(url,data, { headers });
  },
};

export default roomSensorDataApi;
