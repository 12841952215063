import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listUser: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  paging: {},
};
export const userRegisterAlarmSlice = createSlice({
  name: "userRegisterAlarm",
  initialState,
  reducers: {
    userRegAlarmRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
    }),
    userRegAlarmSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      listUser: action.payload,
    }),
    userRegAlarmFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    getPaging: (state, action) => {
      state.paging = action.payload;
    },
  },
});
export const { userRegAlarmRequest, userRegAlarmSuccess, userRegAlarmFailure, getPaging } =
  userRegisterAlarmSlice.actions;
export default userRegisterAlarmSlice.reducer;
