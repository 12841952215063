import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: "",
  locationCookie: "",
  locationItem: "",
  paging: "",
  isSuccess: false,
  isLoading: false,
  isError: false,
  errorMessage: "",
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    hydrate: (state, action) => {
      return action.payload;
    },
    getLocationItem: (state, action) => {
      state.locationItem = action.payload;
    },
    getLocationCookie: (state, action) => {
      state.locationCookie = action.payload;
    },

    getPaging: (state, action) => {
      state.paging = action.payload;
    },
    locationRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    }),
    locationSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      locations: action.payload,
    }),
    locationFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
  },
});

export const {
  getLocationItem,
  getLocationCookie,
  getPaging,
  hydrate,
  locationRequest,
  locationSuccess,
  locationFailure,
} = locationSlice.actions;
export default locationSlice.reducer;
