import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  templates: [],
  paging: {},
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
};
export const templateSlice = createSlice({
  name: "template",
  initialState: initialState,
  reducers: {
    templateRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    }),
    templateSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      templates: action.payload,
    }),
    templateFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    setPaging: (state, action) => {
      state.paging = action.payload;
    },
  },
});
export const { templateRequest, templateSuccess, templateFailure, setPaging } = templateSlice.actions;
export default templateSlice.reducer;
