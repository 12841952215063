import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";

const DashboardLayout = lazy(() => import("./layouts/dashboard/DashboardLayout"));
const DashboardLocationLayout = lazy(() => import("./layouts/dashboard/DashboardLocationLayout"));
const DashboardUnit = lazy(() => import("./layouts/dashboard/DashboardUnit"));
const EventDashboard = lazy(() => import("./pages/events/EventDashboard"));
const EventLocation = lazy(() => import("./pages/events/EventLocation"));
const EventSystem = lazy(() => import("./pages/events/EventSystem"));
const RoomDetail = lazy(() => import("./pages/room/RoomDetail"));
const SerialNumberManage = lazy(() => import("./pages/serialNumber/SerialNumberManage"));
const AddSerial = lazy(() => import("./pages/serialNumber/AddSerial"));
const EditUser = lazy(() => import("./pages/user/EditUser"));
const AddUser = lazy(() => import("./pages/user/AddUser"));
const UserManage = lazy(() => import("./pages/user/UserManage"));
const RegisterValidate = lazy(() => import("./pages/auth/RegisterValidate"));
const ManageTemplate = lazy(() => import("./pages/templateIO/ManageTemplate"));
const ConfigTemplateIO = lazy(() => import("./pages/templateIO/AddTemplateIO"));
const CalibA = lazy(() => import("./pages/setting/CalibA"));
const CalibB = lazy(() => import("./pages/setting/CalibB"));
const CalibC = lazy(() => import("./pages/setting/CalibC"));
const CalibD = lazy(() => import("./pages/setting/CalibD"));
const O2 = lazy(() => import("./pages/setting/O2"));
const SettingLayout = lazy(() => import("./pages/setting/SettingLayout"));
const System = lazy(() => import("./pages/setting/System"));
const Pressure = lazy(() => import("./pages/setting/Pressure"));
const Time = lazy(() => import("./pages/setting/Time"));
const HeatingCooling = lazy(() => import("./pages/setting/Heating_Cooling"));
const HomePage = lazy(() => import("./pages/HomePage"));
const DashBoardPage = lazy(() => import("./pages/DashboardPage"));
const HistoryPage = lazy(() => import("./pages/histories/HistoryPage"));
const RegisterPage = lazy(() => import("./pages/auth/RegisterPage"));
const SettingPage = lazy(() => import("./pages/setting/SettingPage"));
const LoginPage = lazy(() => import("./pages/auth/LoginPage"));
const AlarmPage = lazy(() => import("./pages/alarm/AlarmPage"));
const GraphPage = lazy(() => import("./pages/graphs/GraphPage"));
const AddLocation = lazy(() => import("./pages/location/AddLocation"));
const AddRoomLocation = lazy(() => import("./pages/location/AddRoomLocation"));
const Location = lazy(() => import("./pages/location/Location"));
const LocationDetail = lazy(() => import("./pages/location/LocationDetail"));
const ManageTreatment = lazy(() => import("./pages/location/ManageTreatment"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPasswordPage"));
const ResetPassword = lazy(() => import("./pages/auth/ResetPassword"));
const EditTemplateConfig = lazy(() => import("./pages/templateIO/EditTemplateConfig"));
const DisplayLogSocket = lazy(() => import("./components/utils/DisplayLogSocket"));

function App() {
  return (
    <div className="main">
      <Suspense fallback={<div></div>}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/location-detail" element={<LocationDetail />} />
          <Route path="/room-detail/:qr_code" element={<RoomDetail />} />
          <Route path="/register-validate" element={<RegisterValidate />} />
          <Route element={<DashboardLayout></DashboardLayout>}>
            <Route path="/home" element={<HomePage />} />
            <Route path="/location/add-location" element={<AddLocation />} />
            <Route path="/location/edit-location" element={<AddLocation />} />

            <Route path="/manage/user" element={<UserManage />} />
            <Route path="/manage/add-user" element={<AddUser />} />
            <Route path="/manage/edit-user" element={<EditUser />} />

            <Route path="/manage/event" element={<EventSystem />} />

            <Route path="/manage/manage-serial" element={<SerialNumberManage />} />
            <Route path="/manage/add-serial" element={<AddSerial />} />

            <Route path="/manage/history" element={<HistoryPage />} />

            <Route path="/manage/template-io" element={<ManageTemplate />} />
            <Route path="/manage/Add-templateIO" element={<ConfigTemplateIO />} />
          </Route>
          <Route element={<DashboardUnit></DashboardUnit>}>
            <Route path="/location/setting" element={<Location />} />
            <Route path="/location/treatment" element={<ManageTreatment />} />
            <Route path="/location/event" element={<EventLocation />} />
            <Route path="/location/add-room-location" element={<AddRoomLocation />} />
            <Route path="/location/edit-room-location" element={<AddRoomLocation />} />
            <Route path="/room/template_config" element={<EditTemplateConfig />} />
          </Route>
          <Route element={<DashboardLocationLayout></DashboardLocationLayout>}>
            <Route path="/dashboardHome" element={<DashBoardPage />} />
            <Route path="/dashboard/graph" element={<GraphPage />} />
            <Route path="/dashboard/event" element={<EventDashboard />} />
            <Route path="/dashboard/view-alarm" element={<AlarmPage />} />
            <Route path="/dashboard/02" element={<SettingPage />} />
            <Route path="/room/info" element={<DisplayLogSocket />} />
            <Route element={<SettingLayout />}>
              <Route path="/dashboard/o2" element={<O2 />} />
              <Route path="/dashboard/heating_cooling" element={<HeatingCooling />} />
              <Route path="/dashboard/time" element={<Time />} />
              <Route path="/dashboard/pressure" element={<Pressure />} />
              <Route path="/dashboard/calib-a" element={<CalibA />} />
              <Route path="/dashboard/calib-b" element={<CalibB />} />
              <Route path="/dashboard/calib-c" element={<CalibC />} />
              <Route path="/dashboard/calib-d" element={<CalibD />} />
              <Route path="/dashboard/system" element={<System />} />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
