import { configureStore } from "@reduxjs/toolkit";
import alarmSlice from "../features/alarm/alarmSlice";
import authSlice from "../features/auth/authSlice";
import eventSlice from "../features/event/eventSlice";
import gatewayLocationSlice from "../features/location/gateway_location/gatewayLocationSlice";
import locationSlice from "../features/location/locationSlice";
import userLocationSlice from "../features/location/user_location/userLocationSlice";
import registrationSlice from "../features/registers/registrationSlice";
import { roomSensorDataSlice, roomSlice } from "../features/rooms";
import displaySensorSlice from "../features/rooms/roomDisplaySensor/displaySensorSlice";
import roomSettingSlice from "../features/rooms/roomSetting/roomSettingSlice";
import treatmentSlice from "../features/rooms/treatment/treatmentSlice";
import serialNumberSlice from "../features/serialNumber/serialNumberSlice";
import statusStartSlice from "../features/status/statusStartSlice";
import templateSlice from "../features/templates/template/templateSlice";
import templateConfigSlice from "../features/templates/templateConfig/templateConfigSlice";
import userSlice from "../features/users/user/userSlice";
import userRegisterAlarmSlice from "../features/users/userRegisterAlarm/userRegisterAlarmSlice";
import systemTemplateSlice from "../features/templates/systemTemplate/systemTemplateSlice";

export default configureStore({
  reducer: {
    status: statusStartSlice,
    location: locationSlice,
    auth: authSlice,
    user: userSlice,
    registration: registrationSlice,
    userLocation: userLocationSlice,
    gateLocation: gatewayLocationSlice,
    room: roomSlice,
    displaySensor: displaySensorSlice,
    roomSensorData: roomSensorDataSlice,
    serialNumber: serialNumberSlice,
    treatment: treatmentSlice,
    template: templateSlice,
    templateConfig: templateConfigSlice,
    systemTemplate: systemTemplateSlice,
    event: eventSlice,
    alarm: alarmSlice,
    roomSetting: roomSettingSlice,
    userRegisterAlarm: userRegisterAlarmSlice,
  },
});
