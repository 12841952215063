import axios from "axios";
import roomSensorDataApi from "../../../../api/room/roomSensorDataApi";
import { loading, roomSensorDataFailure, roomSensorDataRequest, roomSensorDataSuccess } from "./roomSensorDataSlice";
import { toast } from "react-toastify";

export const addRoomSensorData = (data) => async (dispatch) => {
  try {
    dispatch(roomSensorDataRequest());
    const response = await roomSensorDataApi.addRoomSensorData(data);
    response.data.code === 200
      ? dispatch(roomSensorDataSuccess(response.data.results))
      : dispatch(roomSensorDataFailure(response.data.message));
  } catch (error) {
    dispatch(roomSensorDataFailure(error.message));
  }
};
export const updateRoomSensorData = (id, data) => async (dispatch) => {
  try {
    dispatch(roomSensorDataRequest());
    const response = await roomSensorDataApi.updateRoomSensorData(id, data);
    response.data.code === 200
      ? dispatch(roomSensorDataSuccess(response.data.results))
      : dispatch(roomSensorDataFailure(response.data.message));
  } catch (error) {
    dispatch(roomSensorDataFailure(error.message));
  }
};
export const deleteRoomSensorData = (id) => async (dispatch) => {
  try {
    dispatch(roomSensorDataRequest());
    const response = await roomSensorDataApi.deleteRoomSensorData(id);
    response.data.code === 200
      ? dispatch(roomSensorDataSuccess(response.data.results))
      : dispatch(roomSensorDataFailure(response.data.message));
  } catch (error) {
    dispatch(roomSensorDataFailure(error.message));
  }
};
export const getRoomSensorData = (id) => async (dispatch) => {
  try {
    dispatch(roomSensorDataRequest());
    const response = await roomSensorDataApi.getRoomSensorData(id);
    response.data.code === 200
      ? dispatch(roomSensorDataSuccess(response.data.results))
      : dispatch(roomSensorDataFailure(response.data.message));
  } catch (error) {
    dispatch(roomSensorDataFailure(error.message));
  }
};
export const getAllRoomSensorData = (roomId, timeFrom, timeTo, variable, locationId) => async (dispatch) => {
  try {
    dispatch(roomSensorDataRequest());
    const response = await roomSensorDataApi.getAllRoomSensorData(roomId, timeFrom, timeTo, variable, locationId);
    console.log(response);
    response.data.code === 2000
      ? dispatch(roomSensorDataSuccess(response.data.result))
      : dispatch(roomSensorDataFailure(response.data.message));
  } catch (error) {
    dispatch(roomSensorDataFailure(error.message));
  }
};

export const exportExcel = (data, locationId, roomId) => async (dispatch) => {
  try {
    dispatch(roomSensorDataRequest())
    const response = await roomSensorDataApi.exportRoomSensorData(
      data,
      locationId,
      roomId
    );
    if (response?.data?.result?.url) {
      const {url} = response?.data?.result
      downloadFile(url, 'exported_data.xlsx');
      toast.success('Export excel completed successfully');
      dispatch(loading(false))
    } else {
      toast.error('Invalid or missing URL in the response');
      dispatch(loading(false))
    }
  } catch (error) {
    console.error(error.message);
  }
};
async function downloadFile(url, fileName) {
  try {
    const response = await axios({
      url: url,
      method: 'GET',
      responseType: 'blob', 
    });
    const contentType = response.headers['content-type'] || 'application/octet-stream';
    const blob = new Blob([response.data], {
      type: contentType,
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    link.setAttribute('target', '_blank'); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading file:', error.message);
    throw error;
  }
}
