import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  events: [],
  paging: [],
};
export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    eventRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }),
    eventSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      isError: false,
      events: action.payload,
    }),
    eventFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    getPaging: (state, action) => {
      state.paging = action.payload;
    },
  },
});
export const { eventRequest, eventSuccess, eventFailure, getPaging } = eventSlice.actions;
export default eventSlice.reducer;
