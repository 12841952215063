import axiosClient from "../axiosClient";

const roomApi = {
  getRoom: (id) => {
    const url = `/api/v1/room/room/${id}`;
    return axiosClient.get(url);
  },

  getAllRoom: (key, value, locationId) => {
    const url = `/api/v1/room/room?${key}=${value}`;
    const headers = { "location-id": locationId };
    return axiosClient.get(url, { headers });
  },

  addRoom: (data, locationId) => {
    const url = `/api/v1/room/room`;
    const headers = { "location-id": locationId };
    return axiosClient.post(url, data, { headers });
  },

  updateRoom: (id, data, locationId, oldData) => {
    const url = `/api/v1/room/room/${id}`;
    const headers = {
      "location-id": locationId,
      "room-id": id,
      "x-old-value": JSON.stringify(oldData),
    };
    return axiosClient.put(url, data, { headers });
  },

  deleteRoom: (id, locationId) => {
    const url = `/api/v1/room/room/${id}`;
    const headers = { "location-id": locationId, "room-id": id };
    return axiosClient.delete(url, { headers });
  },
  generateQrCode: (roomId, locationId, oldData) => {
    const url = `/api/v1/room/room/update_qr_code/${roomId}`
    const headers = {'location-id': locationId, "room-id": roomId,"x-old-value": oldData}
    return axiosClient.put(url, headers)
  },
  getQrCode: (qrCode) => {
    const url = `/api/v1/room/room/qr_code/${qrCode}`
    return axiosClient.get(url)
  },
  getRoomToQrCode: (qrCode, expireToken) => {
    const url = `/api/v1/room/room/qr_code/${qrCode}?expire_token=${expireToken}`
    return axiosClient.get(url)
  }
};

export default roomApi;
