import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  gatewayLocations: "",
};
export const gatewayLocationSlice = createSlice({
  name: "gateLocation",
  initialState,
  reducers: {
    gatewayRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
    }),
    gatewaySuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      gatewayLocations: action.payload,
    }),
    gatewayFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
  },
});
export const { gatewayRequest, gatewaySuccess, gatewayFailure } = gatewayLocationSlice.actions;
export default gatewayLocationSlice.reducer;
