import { toast } from "react-toastify";
import roomApi from "../../../../api/room/roomApi";
import { roomFailure, roomRequest, roomSuccess, setPaging, setRoom } from "./roomSlice";

export const addRoom = (data, locationId) => async (dispatch) => {
  try {
    dispatch(roomRequest());
    const response = await roomApi.addRoom(data, locationId);
    if (response.data.code === 200) {
      dispatch(roomSuccess(response.data.results));
      toast.success("Add new room successfully!!!");
    } else {
      dispatch(roomFailure(response.data.errors.message));
      toast.error(response.data.errors.message);
    }
  } catch (error) {
    dispatch(roomFailure(error.message));
    toast.error(error.message);
  }
};
export const updateRoom = (id, data, locationId, oldData) => async (dispatch) => {
  try {
    dispatch(roomRequest());
    const response = await roomApi.updateRoom(id, data, locationId, oldData);
    if (response.data.code === 200) {
      dispatch(roomSuccess(response.data.results));
    } else {
      dispatch(roomFailure(response.data.message));
    }
  } catch (error) {
    dispatch(roomFailure(error.message));
  }
};
export const deleteRoom = (id, locationId) => async (dispatch) => {
  try {
    dispatch(roomRequest());
    const response = await roomApi.deleteRoom(id, locationId);
    if (response.data.code === 200) {
      toast.success("Delete room successfully");
    } else {
      toast.error("Delete failed.");
    }
  } catch (error) {
    dispatch(roomFailure(error.message));
  }
};
export const getRoom = (id) => async (dispatch) => {
  try {
    dispatch(roomRequest());
    const response = await roomApi.getRoom(id);
    response.data.code === 200
      ? dispatch(roomSuccess(response.data.result))
      : dispatch(roomFailure(response.data.message));
  } catch (error) {
    dispatch(roomFailure(error.message));
  }
};
export const getAllRoom = (key, value, locationId) => async (dispatch) => {
  try {
    dispatch(roomRequest());
    const response = await roomApi.getAllRoom(key, value, locationId);
    dispatch(setPaging(response.data.paging));
    response.data.code === 200
      ? dispatch(roomSuccess(response.data.results))
      : dispatch(roomFailure(response.data.message));
  } catch (error) {
    dispatch(roomFailure(error.message));
  }
};
export const setRoomItem = (room) => (dispatch) => {
  localStorage.setItem("room", btoa(JSON.stringify(room)));
  dispatch(setRoom(room));
};
export const generateQrCode = (roomId, locationId) => async (dispatch) => {
  try {
    const response = await roomApi.generateQrCode(roomId, locationId);
    console.log(response);
    toast.success("Generate sussessfully!!!");
  } catch (error) {
    toast.error(error.message);
  }
};
