import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  alarms: [],
  paging: "",
};
export const alarmSlice = createSlice({
  name: "alarm",
  initialState,
  reducers: {
    alarmRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
    }),
    alarmSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      alarms: action.payload,
    }),
    alarmFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
    setPaging: (state, action) => {
      state.paging = action.payload;
    },
  },
});

export const { alarmFailure, alarmRequest, alarmSuccess, setPaging } = alarmSlice.actions;
export default alarmSlice.reducer;
