import "react-js-dropdavn/dist/index.css";
import "./styles/index.scss";
import "animate.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import store from "./stores/app/store";
import { getLocationCookie } from "./stores/features/location/locationSlice";
import Cookies from "js-cookie";
import { reloadUser } from "./stores/features/auth/authAction";
import "react-loading-skeleton/dist/skeleton.css";
import ReactModal from "react-modal";
import { setRoom } from "./stores/features/rooms/room/roomSlice";
import CryptoJS from "crypto-js";

ReactModal.setAppElement("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));
const users = Cookies.get("auth");
const locationItem = Cookies.get("location");
const room = localStorage.getItem("room");
let user = "";
let locationItemValue = "";
const key = process.env.REACT_APP_KEYENCODE
if (users !== undefined) {
  try {
    const bytes  = CryptoJS.AES.decrypt(users, key);
    const originalToken = bytes.toString(CryptoJS.enc.Utf8);
    user = JSON.parse(originalToken);
  } catch (error) {
    console.log("");
  }
}
if (locationItem !== undefined) {
  try {
     const bytes  = CryptoJS.AES.decrypt(locationItem, key);
    const originalToken = bytes.toString(CryptoJS.enc.Utf8);
    locationItemValue = JSON.parse(originalToken);
  } catch (error) {
    console.log("");
  }
}
const appComponent = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <ToastContainer />
    </BrowserRouter>
  </Provider>
);

if (locationItemValue) {
  store.dispatch(getLocationCookie(locationItemValue));
}

if (user) {
  store.dispatch(reloadUser(user));
}
if (room) {
  store.dispatch(setRoom(JSON.parse(atob(room))));
}

root.render(appComponent);
