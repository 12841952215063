import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  templateConfig: [],
};
export const templateConfigSlice = createSlice({
  name: "templateConfig",
  initialState,
  reducers: {
    templateConfigRequest: (state) => ({
      ...state,
      isLoading: true,
      isSuccess: false,
      isError: false,
      errorMessage: "",
    }),
    templateConfigSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isSuccess: true,
      templateConfigs: action.payload,
    }),
    templateConfigFailure: (state, action) => ({
      ...state,
      isLoading: false,
      isError: true,
      errorMessage: action.payload,
    }),
  },
});
export const { templateConfigRequest, templateConfigSuccess, templateConfigFailure } = templateConfigSlice.actions;
export default templateConfigSlice.reducer;
