// authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    listUser: [],
    token: null,
    isAuthenticated: false,
    isLoading: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuthenticated = true;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setListUser: (state, action) => {
      state.listUser = action.payload;
    },
    loginRequest: (state) => {
      state.isLoading = true;
      state.isAuthenticated = false;
    },
    loginSuccess: (state, action) => ({
      ...state,
      isLoading: false,
      isAuthenticated: true,
      user: action.payload,
    }),
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = action.payload;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.isReload = false;
    },
  },
});

export const { setUser, setToken, setListUser, logout, reload, loginRequest, loginSuccess, loginFailure } =
  authSlice.actions;

export default authSlice.reducer;
