// registrationSlice.js
import { createSlice } from "@reduxjs/toolkit";

const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: null,
  },
  reducers: {
    registrationRequest: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
    },
    registrationSuccess: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    registrationFailure: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const { registrationRequest, registrationSuccess, registrationFailure } = registrationSlice.actions;

export default registrationSlice.reducer;
